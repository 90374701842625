@font-face {
    font-family: Gotham;
    src: url("../fonts/Gotham-Narrow-Book_3.otf") format("opentype");
    font-weight: 400;
}
@font-face {
    font-family: Gotham;
    src: url("../fonts/Gotham-Narrow-Bold.otf") format("opentype");
    font-weight: 700;
}
@font-face {
    font-family: Gotham;
    src: url("../fonts/Gotham-Narrow-Black.otf") format("opentype");
    font-weight: 900;
}
@font-face {
    font-family: Gotham;
    src: url("../fonts/Gotham-Narrow-Ultra.otf") format("opentype");
    font-weight: 1000;
}
@font-face {
    font-family: Gotham;
    src: url("../fonts/Gotham\ XNarrow\ Book\ Italic_3.otf") format("opentype");
    font-weight: 400;
    font-style: italic;
}
@font-face {
    font-family: Gotham_Rounded;
    src: url("../fonts/Gotham-Rounded-Bold.otf") format("opentype");
    font-weight: 700;
}
